import React, { useEffect, useRef, FC } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  DraftHandleValue,
  ContentBlock,
  CompositeDecorator,
} from "draft-js";
import DraftToolbar from "./DraftToolbar";
import "./DraftEditor.css";

import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import "highlight.js/styles/base16/classic-dark.css";

hljs.registerLanguage("javascript", javascript);

interface DraftEditorProps {
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
  readOnly?: boolean;
}

const DraftEditor: FC<DraftEditorProps> = ({
  editorState,
  setEditorState,
  readOnly = false,
}) => {
  const editor = useRef<Editor | null>(null);

  useEffect(() => {
    focusEditor();
    highlightCodeBlocks();
  }, []);

  const focusEditor = (): void => {
    editor.current?.focus();
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const styleMap: { [key: string]: React.CSSProperties } = {
    CODE: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
    HIGHLIGHT: {
      backgroundColor: "#F7A5F7",
    },
    UPPERCASE: {
      textTransform: "uppercase",
    },
    LOWERCASE: {
      textTransform: "lowercase",
    },
    SUPERSCRIPT: {
      verticalAlign: "super",
      fontSize: "80%",
    },
    SUBSCRIPT: {
      verticalAlign: "sub",
      fontSize: "80%",
    },
    LINK: {
      color: "#0645AD", // Added LINK style
      textDecoration: "underline",
    },
  };

  const linkDecorator = new CompositeDecorator([
    {
      strategy: (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "LINK"
          );
        }, callback);
      },
      component: (props) => {
        const { entityKey, children } = props;
        const entity = props.contentState.getEntity(entityKey);
        const { url } = entity.getData();
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      },
    },
  ]);

  const myBlockStyleFn = (contentBlock: ContentBlock): string => {
    const type = contentBlock.getType();
    switch (type) {
      case "code-block":
        return "codeBlock";
      case "blockQuote":
        return "superFancyBlockquote";
      case "leftAlign":
        return "leftAlign";
      case "rightAlign":
        return "rightAlign";
      case "centerAlign":
        return "centerAlign";
      case "justifyAlign":
        return "justifyAlign";
      default:
        return "";
    }
  };

  const highlightCodeBlocks = () => {
    const codeBlocks = document.querySelectorAll(".codeBlock");
    codeBlocks.forEach((block) => {
      const htmlBlock = block as HTMLElement;
      const codeContent = htmlBlock.innerText;
      const codeElement = document.createElement("code");
      codeElement.className = "language-javascript";
      codeElement.textContent = codeContent;
      block.innerHTML = "";
      block.appendChild(codeElement);
      hljs.highlightElement(codeElement);
    });
  };

  // Apply the CompositeDecorator when creating or updating the EditorState
  const updatedEditorState = EditorState.set(editorState, {
    decorator: linkDecorator,
  });

  return (
    <div
      className={readOnly ? "draft-display-wrapper" : "editor-wrapper"}
      onClick={focusEditor}
    >
      {!readOnly && (
        <div className="toolbar-container">
          <DraftToolbar
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </div>
      )}
      <div
        className={readOnly ? "draft-display-container" : "editor-container"}
      >
        <Editor
          ref={editor}
          placeholder="Write Here"
          handleKeyCommand={handleKeyCommand}
          editorState={updatedEditorState}
          customStyleMap={styleMap}
          blockStyleFn={myBlockStyleFn}
          readOnly={readOnly}
          onChange={setEditorState}
        />
      </div>
    </div>
  );
};

export default DraftEditor;
