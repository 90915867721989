import api from '../api';
import { Dispatch } from 'redux';
import { GET_ARTICLE_BY_ID, ADD_ARTICLE, LIST_OF_ARTICLES, EDIT_ARTICLE, DELETE_ARTICLE, CLEAR_ARTICLE_DATA, ArticleAction } from '../_types';

export const getArticleByID = (id: string) => async (dispatch: Dispatch<ArticleAction>) => {
  dispatch({
    type: GET_ARTICLE_BY_ID,
    status: 'loading',
  });
  try {
    const response = await api.get(`/article/${id}`);
    dispatch({
      type: GET_ARTICLE_BY_ID,
      status: 'success',
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: GET_ARTICLE_BY_ID,
      status: 'failure',
      payload: error || 'An unknown error occurred',
    });
  }
};

export const getArticles = () => async (dispatch: Dispatch<ArticleAction>) => {
  dispatch({
    type: LIST_OF_ARTICLES,
    status: 'loading',
  });
  try {
    const response = await api.get('/article');
    dispatch({
      type: LIST_OF_ARTICLES,
      status: 'success',
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: LIST_OF_ARTICLES,
      status: 'failure',
      payload: error || 'An unknown error occurred',
    });
  }
};

export const adminGetArticleByID = (id: string) => async (dispatch: Dispatch<ArticleAction>) => {
  try {
    const response = await api.get(`/admin/article/${id}`);
    dispatch({
      type: GET_ARTICLE_BY_ID,
      status: 'success',
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: GET_ARTICLE_BY_ID,
      status: 'failure',
      payload: error || 'An unknown error occurred',
    });
  }
};

export const adminGetArticles = () => async (dispatch: Dispatch<ArticleAction>) => {
  try {
    const response = await api.get('/admin/article');
    dispatch({
      type: LIST_OF_ARTICLES,
      status: 'success',
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: LIST_OF_ARTICLES,
      status: 'failure',
      payload: error || 'An unknown error occurred',
    });
  }
};

export const addArticle = (articleData: any) => async (dispatch: Dispatch<ArticleAction>) => {
  try {
    const response = await api.post('/admin/article', articleData);
    dispatch({
      type: ADD_ARTICLE,
      status: 'success',
      payload: response
    });
  } catch (error) {
    dispatch({
      type: ADD_ARTICLE,
      status: 'failure',
      payload: error || 'An unknown error occurred',
    });
  }
};

export const editArticle = (articleData: any) => async (dispatch: Dispatch<ArticleAction>) => {
  try {
    const response = await api.put(`/admin/article/${articleData._id}`, articleData); 
    dispatch({
      type: EDIT_ARTICLE,
      status: 'success',
      payload: response
    });
  } catch (error) {
    dispatch({
      type: EDIT_ARTICLE,
      status: 'failure',
      payload: error || 'An unknown error occurred',
    });
  }
};

export const deleteArticle = (id: string) => async (dispatch: Dispatch<ArticleAction>) => {
  try {
    const response = await api.delete(`/admin/article/${id}`);
    dispatch({
      type: DELETE_ARTICLE,
      status: 'success',
      payload: {
        id,
        status: response.status,
        msg: response.data.msg
      }, 
    });
  } catch (error:any) {
    dispatch({
      type: DELETE_ARTICLE,
      status: 'failure',
      payload: {
        id,
        status: error.response.status,
        msg: error.response.data.msg
      }
    });
  }
};

export const clearArticleData = () => ({
  type: CLEAR_ARTICLE_DATA,
});
