import React from "react";

import {
  Container,
  Box,
  Typography,
  Grid,
  Avatar,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Chip,
} from "@mui/material";
import { Parallax } from "react-parallax";
import { useMediaQuery } from "@mui/material";
import { Google as GoogleIcon, Instagram, WhatsApp } from "@mui/icons-material";

import bootstrap from "../../assets/techIcons/bootstrap.svg";
import css from "../../assets/techIcons/css.svg";
import cypress from "../../assets/techIcons/cypress.svg";
import express from "../../assets/techIcons/express.svg";
import git from "../../assets/techIcons/git.svg";
import html from "../../assets/techIcons/html.svg";
import javascript from "../../assets/techIcons/javascript.svg";
import nodejs from "../../assets/techIcons/nodejs.svg";
import react from "../../assets/techIcons/react_logo.svg";
import sass from "../../assets/techIcons/sass.svg";
import typescript from "../../assets/techIcons/typescript.svg";
import mongodb from "../../assets/techIcons/mongodb.svg";

import profile2 from "../../assets/profile2.jpg";

const About = () => {
  const skills = [
    nodejs,
    react,
    mongodb,
    cypress,
    javascript,
    typescript,
    html,
    css,
    express,
    sass,
    git,
    bootstrap,
  ];
  const isMobile = useMediaQuery("(max-width:600px)");
  const innerStyles = {
    img: {
      minHeight: 20,
      height: isMobile ? 40 : 60, // Dynamic height based on screen size
    },
  };

  return (
    <>
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          About Me
        </Typography>
        <Box mb={4}>
          <Typography variant="h6">
            Hello! I’m Jayakar Reddy, a passionate Software Engineer dedicated
            to the art of building and sharing. My journey into web development
            began as a hobby, fueled by curiosity and the desire to create. Over
            time, this passion transformed into a professional pursuit, allowing
            me to blend creativity with technical skills.
            {/* I also like to make{" "}
            <a href="https://github.com/Jayakarreddy">
              <b>open-source projects</b>
            </a>
            , all of which can be found on{" "}
            <a href="https://github.com/Jayakarreddy">
              <b>GitHub</b>
            </a>
            . */}
          </Typography>
        </Box>
      </Container>

      <Parallax
        bgImage="https://images.unsplash.com/photo-1669490893500-97456444f578?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        strength={300}
      >
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
            }}
          ></div>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              position: "relative",
              zIndex: 2,
              minHeight: 400,
              padding: "40px 0px",
            }}
          >
            <Container maxWidth="md" sx={{ color: "#fff" }}>
              <Typography variant="h6">
                Currently, I work full-time as a Software Engineer, where I
                tackle challenges that push the boundaries of my knowledge and
                expertise. Alongside my job, I actively engage in building
                open-source projects, believing in the power of community and
                collaboration. All my projects can be found on GitHub, where I
                strive to contribute to the open-source ecosystem.
              </Typography>
              <br />
              <Typography variant="h6">
                In addition to development, I enjoy writing tech blogs that
                share insights, tutorials, and lessons learned from my
                experiences in the field. I hope these writings inspire others
                on their own journeys and provide valuable resources for both
                beginners and seasoned developers.
              </Typography>
            </Container>
          </Box>
        </div>
      </Parallax>

      <Container maxWidth="md">
        <Card elevation={0} sx={{ background: "none", margin: "30px 0px" }}>
          <CardHeader
            sx={{
              flexDirection: "column",
              paddingBottom: "unset",
              ".MuiCardHeader-avatar": {
                marginRight: "unset",
              },
            }}
            avatar={<Avatar src={profile2} sx={{ width: 90, height: 90 }} />}
            title="Jayakar Reddy"
            subheader="Web Developer"
          />
          <CardContent
            sx={{
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle2">
              Follow me on Instagram for personal updates and insights!
            </Typography>
            <Typography variant="subtitle2">
              Reach out to me on WhatsApp for quick responses to any questions
              or assistance!
            </Typography>
            <Typography variant="subtitle2">
              Send me an email via Gmail for detailed queries, collaborations,
              or business inquiries.
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              justifyContent: "center",
            }}
          >
            <Chip
              sx={{
                background:
                  "linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)",
                color: "white",
                "& .MuiChip-icon": {
                  color: "white",
                },
              }}
              clickable
              icon={<Instagram />}
              label="Follow"
              component={"a"}
              href={"https://www.instagram.com/jayakar_reddy_marthala/"}
              target="_blank"
            />
            <Chip
              sx={{
                background: "linear-gradient(45deg, #25D366 30%, #128C7E 90%)",
                color: "white",
                "& .MuiChip-icon": {
                  color: "white",
                },
              }}
              clickable
              icon={<WhatsApp />}
              label="Whatsapp"
              component={"a"}
              href="https://wa.me/918712371921?text=Hi%20Jayakar"
              target="_blank"
            />
            <Chip
              sx={{
                background: "linear-gradient(45deg, #D93025 30%, #C5221F 90%)",
                color: "white",
                "& .MuiChip-icon": {
                  color: "white",
                },
              }}
              clickable
              icon={<GoogleIcon />}
              label="Mail me"
              component={"a"}
              href="mailto:jayakarbunnyreddy@gmail.com"
              target="_blank"
            />
          </CardActions>
        </Card>
      </Container>

      <Parallax
        bgImage="https://images.unsplash.com/photo-1723465554887-5735eb613398?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        strength={300}
      >
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
            }}
          ></div>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              position: "relative",
              zIndex: 2,
              minHeight: 300,
              padding: "40px 0px",
            }}
          >
            <Container maxWidth="md" sx={{ color: "#fff" }}>
              <Typography variant="h6">
                I also offer freelance web application development services. If
                you're interested in collaborating or have a project in mind,
                feel free to check out my freelancing contact page. My aim is to
                create functional, user-friendly websites that not only meet
                client needs but also enhance the overall user experience.
              </Typography>
            </Container>
          </Box>
        </div>
      </Parallax>

      <Container maxWidth="md">
        <Box mt={4} mb={4}>
          <Typography variant="h6">
            Outside of coding, I have a growing interest in photography.
            Capturing moments and expressing creativity through the lens adds a
            unique dimension to my life. You can view my photography work on my
            photos page, where I share my favorite shots and projects.
          </Typography>
        </Box>
      </Container>

      <Parallax
        bgImage="https://images.unsplash.com/photo-1500989145603-8e7ef71d639e?q=80&w=1776&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        strength={300}
      >
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(225, 225, 225, 0.5)",
              zIndex: 1,
            }}
          ></div>
          <Box
            maxHeight={500}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "30px 0px", position: "relative", zIndex: 2 }}
          >
            <Container
              maxWidth="md"
              sx={{ textAlign: "center", textTransform: "uppercase" }}
            >
              <Typography variant="h6">Technologies & Skills</Typography>
              <br />
              <br />
              <Grid container spacing={2}>
                {skills.map((item) => (
                  <Grid item textAlign={"center"} lg={3} md={3} sm={6} xs={6}>
                    <img src={item} style={innerStyles.img} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
        </div>
      </Parallax>
    </>
  );
};

export default About;
