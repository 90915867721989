import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../store";
import { getProfileFromUnsplash } from "../../../_actions/publicActions";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import EmptyData from "../../ui-components/EmptyData/EmptyData";
import landscape_photographer from "../../../assets/illustrations/landscape_photographer.svg";

const GalleryProfile = () => {
  const dispatch: AppDispatch = useDispatch();
  const publicState = useSelector((state: RootState) => state.publicInfo);
  const { unsplashProfile } = publicState;

  useEffect(() => {
    dispatch(getProfileFromUnsplash());
  }, [dispatch]);

  return (
    <>
      {unsplashProfile ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
            width: {
              xs: "100%",
              sm: "100%",
              md: "60%",
              lg: "60%",
              xl: "50%",
            },
          }}
        >
          <Avatar
            src={unsplashProfile.profile_image.large}
            sx={{ width: 130, height: 130 }}
          />
          <Box sx={{ marginLeft: 2 }}>
            {/* <Typography variant="overline">{unsplashProfile.name}</Typography> */}
            <Typography>{unsplashProfile.bio}</Typography>
            <Chip
              sx={{
                padding: "8px 0px 8px 6px",
                marginTop: 1,
                background: "rgba(255, 255, 255, 0.7)",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                "&:hover": {
                  background: "rgba(255, 255, 255, 1)",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                },
              }}
              clickable
              icon={
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/4/4a/Unsplash_wordmark_logo.svg"
                  height={20}
                />
              }
              component={"a"}
              href={unsplashProfile.links.html}
              target="_blank"
              // label={
              //   <Typography variant="button">
              //     {unsplashProfile.username}
              //   </Typography>
              // }
            />
          </Box>
        </Box>
      ) : (
        <EmptyData
          fileName={landscape_photographer}
          title={"Searching..."}
          customHeight="20vh"
          imgHeight={100}
        />
      )}
    </>
  );
};

export default React.memo(GalleryProfile);
