import React from "react";
import {
  Typography,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import moment from "moment";
import EmptyData from "../ui-components/EmptyData/EmptyData";

import server_down from "../../assets/illustrations/server_down.svg";
import CircularLoader from "../ui-components/CircularLoader/CircularLoader";
import defaultIcon from "../../assets/techIcons/web-development.svg";

interface Article {
  _id: number;
  title: string;
  description: string;
  icon: string;
  createdDate: string;
  modifiedDate: string;
}

interface HighlightsProps {
  listOfArticles: Article[];
  loading: boolean;
  error: any;
  handleOpenArticle: (id: number) => void;
}

const Highlights: React.FC<HighlightsProps> = ({
  listOfArticles,
  handleOpenArticle,
  loading,
  error,
}) => {
  return (
    <>
      <Typography variant="h5">Highlights</Typography>

      {loading && <CircularLoader customHeight={250} />}
      {error && (
        <EmptyData
          fileName={server_down}
          title={"Something went wrong"}
          customHeight="auto"
        />
      )}
      {!loading && listOfArticles && listOfArticles.length > 0 && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
          spacing={2}
          sx={{ marginTop: 1 }}
        >
          {listOfArticles.map((item, index) => (
            <Grid key={index} item lg={6} md={6} sm={12}>
              <ListItem
                alignItems="center"
                onClick={() => handleOpenArticle(item._id)}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    ".MuiTypography-body1": {
                      textDecoration: "underline",
                    },
                  },
                }}
              >
                <ListItemAvatar
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "unset",
                  }}
                >
                  <img
                    alt="blog logo"
                    src={item.icon ? item.icon : defaultIcon}
                    style={{ height: 55 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{ margin: "0px 5px 0px 20px", alignSelf: "center" }}
                  primary={
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="text.secondary"
                    >
                      {moment(item.createdDate).format("LLL")}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      color="text.primary"
                      sx={{
                        fontSize: "1.1rem",
                        // fontWeight: 600,
                      }}
                    >
                      {item.title}
                    </Typography>
                  }
                />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default Highlights;
