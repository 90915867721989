import React, { useEffect } from "react";

interface CommentsProps {
  repo: string;
  issueTerm: string;
  theme?: string;
}

const Comments: React.FC<CommentsProps> = ({
  repo,
  issueTerm,
  theme = "github-light",
}) => {
  useEffect(() => {
    const loadCommentsScript = () => {
      const script = document.createElement("script");
      script.src = "https://utteranc.es/client.js";
      script.async = true;
      script.setAttribute("repo", repo);
      script.setAttribute("issue-term", issueTerm);
      script.setAttribute("theme", theme);
      script.setAttribute("crossorigin", "anonymous");

      const commentsContainer = document.getElementById("comments");
      if (commentsContainer) {
        commentsContainer.appendChild(script);
      }
    };

    // Retry loading comments script
    const retryInterval = setInterval(() => {
      const commentsContainer = document.getElementById("comments");
      if (commentsContainer) {
        loadCommentsScript();
        clearInterval(retryInterval);
      }
    }, 1000);

    // Cleanup on component unmount
    return () => {
      clearInterval(retryInterval);
      const commentsContainer = document.getElementById("comments");
      if (commentsContainer) {
        commentsContainer.innerHTML = "";
      }
    };
  }, [repo, issueTerm, theme]);

  return <div id="comments"></div>;
};

export default Comments;
